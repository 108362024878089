import {
  Container,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as React from "react";

import Helmet from "react-helmet";

import { FeaturedExperiences } from "../components/featured-experiences";
import { Hero } from "../components/hero";
import { HowTo } from "../components/howto";
import { PricingTiers } from "../components/pricing-tiers";
import InquiryForm from "../components/inquiry-form";

const useStyles = makeStyles(theme =>
  createStyles({
    topDivider: {
      // display: "none",
      // [theme.breakpoints.down("lg")]: { display: "block" },
    },
    uppercase: { textTransform: "uppercase" },
  })
);

const IndexPage: React.FC = () => {
  const styles = useStyles();
  return (
    <>
      <Helmet
        title="Post Reality - Home"
        meta={[
          {
            name: "description",
            content:
              "The future of presentations with augmented reality. Turn regular posters and presentations into engaging augmented reality experiences in minutes.",
          },
          {
            name: "keywords",
            content:
              "augmented, reality, poster, scientific, education, presentation",
          },
        ]}
      />
      <Container component="main" maxWidth="lg">
        <Hero />
        <Divider className={styles.topDivider} variant="middle" />
        <Typography
          className={styles.uppercase}
          variant="h4"
          align="center"
          // paragraph
        >
          Featured Presentations
        </Typography>
        <Typography
          variant="h5"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          Click on any experience below to view
        </Typography>
        <FeaturedExperiences title="Academic Research" tag="academic" />
        <FeaturedExperiences title="Additional Use Cases" tag="other" />
        {/* <FeaturedExperiences title="Science" tag="science" />
      <FeaturedExperiences title="Arts" tag="arts" /> */}

        <Divider variant="middle" />

        <Typography
          className={styles.uppercase}
          variant="h4"
          align="center"
          paragraph
        >
          Augmented Reality in 3 easy steps
        </Typography>
        <HowTo />

        <Divider variant="middle" />

        <Typography
          className={styles.uppercase}
          variant="h4"
          align="center"
          paragraph
        >
          Interested in Post Reality?
        </Typography>
        <InquiryForm />

        {/* <Divider variant="middle" /> */}

        {/* <Typography
          className={styles.uppercase}
          variant="h4"
          align="center"
          paragraph
        >
          Subscription Plans
        </Typography> */}
        {/* <PricingTiers /> */}
      </Container>
    </>
  );
};

export default IndexPage;
