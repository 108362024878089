import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { mdiEye, mdiThumbUp } from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import React from 'react';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import iconB from '../images/icon_b.png';

import { ServerResponse, XRExperience } from '../types';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '84%',
      margin: theme.spacing(2, 0),
    },
    media: {
      height: 200,
    },
    chips: {
      margin: theme.spacing(1, 0, 0),
    },
  })
);

type Props = {
  experience: XRExperience;
};

// const isBrowser = typeof window !== "undefined";

export const ExperienceCard: React.FC<Props> = ({ experience }) => {
  const styles = useStyles();
  const cardStyles = useSoftRiseShadowStyles();
  const [deeplink, setDeeplink] = React.useState('');

  React.useEffect(() => {
    axios
      .get<ServerResponse & { deeplink: string }>(
        `/api/v1/experience/${experience.uuid}/deeplink`,
        {
          headers: {
            Authorization: `Bearer ` + experience.access_token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => setDeeplink(response.data.deeplink))
      .catch(reason => console.error(reason));
  }, []);

  return (
    <Card classes={cardStyles} className={styles.root} elevation={3}>
      <CardActionArea href={deeplink} target="_blank">
        <CardMedia
          className={styles.media}
          image={
            experience.preview_image_urls &&
            experience.preview_image_urls.length
              ? experience.preview_image_urls[
                  experience.preview_image_urls.length - 1
                ]
              : experience.marker?.url ?? iconB
          }
          title={experience.name}
        />
        <CardContent>
          <Typography variant="h5" noWrap>
            {experience.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            {Boolean(experience.author_name)
              ? experience.author_name
              : 'Anonymous'}
          </Typography>
          <div className={styles.chips}>
            <Chip
              label={`${experience.count_views_h ?? 0} Views`}
              size="small"
              icon={<Icon path={mdiEye} size={0.7} />}
              style={{ marginRight: 8 }}
            />
            <Chip
              label={`${experience.count_likes ?? 0} Likes`}
              size="small"
              icon={<Icon path={mdiThumbUp} size={0.7} />}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
