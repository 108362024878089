import {
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import axios from 'axios';
import Slider from 'infinite-react-carousel';
import React from 'react';
import useSWR from 'swr';

import { PaginatedExperiences, ServerResponse, XRExperience } from '../types';
import { ExperienceCard } from './experience-card';

type Props = {
  title: string;
  tag?: string;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
      '& .carousel-row': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .carousel-arrow': {
        filter: 'grayscale(1)',
      },
      '& .carousel-prev': {
        left: -11,
      },
      '& .carousel-next': {
        right: -11,
      },
    },
    title: {
      marginLeft: theme.spacing(10),
    },
    loading: {
      height: 350,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const isBrowser = typeof window !== 'undefined';

export const FeaturedExperiences: React.FC<Props> = ({ title, tag }) => {
  const styles = useStyles();
  const isXs = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const isSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isMd = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  // const isLg = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const { data, error } = useSWR(
    `/api/v1/experiences?tag=${
      tag ? `featured,${tag}` : 'featured'
    }&add_props=count_views_h,preview_image_urls&page=1&per_page=20`,
    key => axios.get<ServerResponse & PaginatedExperiences>(key)
  );

  if (!isBrowser) return null;

  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant="h5">
        {title}
      </Typography>
      {!data && !error ? (
        <div className={styles.loading}>
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <Slider dots row={1} slidesToShow={isXs ? 1 : isSm ? 2 : isMd ? 3 : 4}>
          {data?.data.experiences.map(exp => (
            <ExperienceCard key={exp.id} experience={exp} />
          ))}
        </Slider>
      )}
    </div>
  );
};
