import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { createUserFeedbackApi } from "../api/xrp";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(theme =>
  createStyles({
    form: { margin: theme.spacing(3, 8, 6) },
    submit: {
      marginTop: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

const whyLabel = "Why are you interested in Post Reality?";
const industryLabel = "What is your industry?";

type OrgInterestFormData = {
  firstname: string;
  lastname: string;
  email: string;
  why: string;
  industry: string;
};

const InquiryForm: React.FC = () => {
  const [sendingInquiry, setSendingInquiry] = React.useState(false);
  const [isHuman, setIsHuman] = React.useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useForm<OrgInterestFormData>();

  const onSubmit = handleSubmit(async formData => {
    try {
      setSendingInquiry(true);
      await createUserFeedbackApi(
        "inquiry",
        `First Name: ${formData.firstname}, Last Name: ${formData.lastname}, Email: ${formData.email}, ${whyLabel}: ${formData.why}, ${industryLabel}: ${formData.industry}`
      );
      enqueueSnackbar("Inquiry sent.", { variant: "success" });
      reset();
    } catch (error) {
      enqueueSnackbar("An error occured. Please try again later.", {
        variant: "error",
      });
    } finally {
      setSendingInquiry(false);
    }
  });

  const handleReCaptchaChange = (token: string | null) => {
    setIsHuman(Boolean(token));
  };

  return (
    <>
      <Typography align="center" gutterBottom>
        To enable PostReality for your organization please reach out to us and
        we will get back to you as soon as possible.
      </Typography>
      <form className={classes.form} id="org-request" onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="firstname"
              control={control}
              defaultValue=""
              rules={{
                required: "This field is required.",
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  helperText={fieldState.error?.message}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="lastname"
              control={control}
              defaultValue=""
              rules={{
                required: "This field is required.",
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  helperText={fieldState.error?.message}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "This field is required.",
                pattern: {
                  value: /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Invalid e-mail format.",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  helperText={fieldState.error?.message}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="why"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required." }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={whyLabel}
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  helperText={fieldState.error?.message}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="industry"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required." }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={industryLabel}
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  helperText={fieldState.error?.message}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <ReCAPTCHA
                  sitekey="6LdQlMMUAAAAADZk2qbGizTy5PT2yAgEjU3vMKLO"
                  onChange={handleReCaptchaChange}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={sendingInquiry || !isHuman}
                >
                  {sendingInquiry ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default InquiryForm;
