import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme =>
  createStyles({
    heading: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    wrapper: {
      width: 350,
      marginBottom: theme.spacing(2),
    },
    image: {
      borderRadius: theme.shape.borderRadius,
    },
  })
);

export const HowTo: React.FC = () => {
  const styles = useStyles();
  return (
    <Grid container justifyContent="space-evenly" spacing={6}>
      <Grid className={styles.item} item md={12} lg={4}>
        <Typography
          className={styles.heading}
          variant="h5"
          align="center"
          gutterBottom
        >
          Upload Centerpiece
        </Typography>

        <StaticImage
          className={styles.wrapper}
          imgClassName={styles.image}
          src="../images/step_1.png"
          alt="Step 1"
        />
        <Typography align="center" paragraph>
          This image is the anchor that ties your AR presentation together
        </Typography>
      </Grid>
      <Grid className={styles.item} item md={12} lg={4}>
        <Typography
          className={styles.heading}
          variant="h5"
          align="center"
          gutterBottom
        >
          Drag 'n' Drop Media
        </Typography>

        <StaticImage
          className={styles.wrapper}
          imgClassName={styles.image}
          src="../images/step_2.png"
          alt="Step 2"
        />
        <Typography align="center" paragraph>
          Add videos, 3D models, images, audio files, links and email buttons
        </Typography>
      </Grid>
      <Grid className={styles.item} item md={12} lg={4}>
        <Typography
          className={styles.heading}
          variant="h5"
          align="center"
          gutterBottom
        >
          Share and View
        </Typography>

        <StaticImage
          className={styles.wrapper}
          imgClassName={styles.image}
          src="../images/step_3.png"
          alt="Step 3"
        />
        <Typography align="center" paragraph>
          Share with anyone and view AR presentations anywhere
        </Typography>
      </Grid>
    </Grid>
  );
};
