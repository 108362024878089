import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "infinite-react-carousel";
import React from "react";

import { base_external } from "../api/xrp";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0, 0),
      [theme.breakpoints.between(
        theme.breakpoints.values.xs,
        theme.breakpoints.values.lg
      )]: {
        padding: theme.spacing(2, 0, 0),
      },
      "& .MuiButton-root": {
        fontSize: theme.typography.h5.fontSize,
        padding: theme.spacing(1, 5),
      },
      "& .MuiTypography-h4": {
        textTransform: "none",
      },
      "& .carousel-row": {
        padding: "40px",
      },
      "& .carousel-arrow": {
        filter: "grayscale(1)",
      },
    },
    logo: {
      width: "100%",
      "& img": {
        display: "block",
      },
    },
    action: {
      display: "flex",
      alignItems: "center",
    },
    slide: {
      borderRadius: theme.shape.borderRadius,
    },
    video: {
      maxWidth: "100%",
      maxHeight: 720,
      borderRadius: theme.shape.borderRadius,
    },
  })
);

const isBrowser = typeof window !== "undefined";

export const Hero: React.FC = () => {
  const styles = useStyles();
  // const isSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  // console.log(isSm);

  if (!isBrowser) return null;

  return (
    <Grid
      className={styles.root}
      container
      spacing={4}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} md={5}>
        <StaticImage
          className={styles.logo}
          src="../images/logo.png"
          alt="Logo"
        />
        <Grid container alignItems="center">
          <Grid item sm={8} md={12}>
            <Typography variant="h4">Enhance Your Presentations</Typography>
            <Typography variant="h4">With Augmented Reality</Typography>
            <Typography variant="h6" paragraph>
              Create, View and Share for FREE!
            </Typography>
          </Grid>
          <Grid item sm={4} md={12}>
            <div className={styles.action}>
              <Button
                href={`${base_external}/auth/signup`}
                size="large"
                color="primary"
                variant="contained"
                target="_blank"
              >
                Start Here
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7}>
        <Slider dots autoplay>
          <video
            className={styles.video}
            src="/diy_ar_poster_presentations.mp4"
            autoPlay
            loop
            muted
          />
          <StaticImage
            className={styles.slide}
            src="../images/carousel_1.png"
            alt="Hero Slide 1"
          />
          <StaticImage
            className={styles.slide}
            src="../images/carousel_2.png"
            alt="Hero Slide 2"
          />
          <StaticImage
            className={styles.slide}
            src="../images/carousel_3.png"
            alt="Hero Slide 3"
          />
          <StaticImage
            className={styles.slide}
            src="../images/carousel_4.png"
            alt="Hero Slide 4"
          />
          <StaticImage
            className={styles.slide}
            src="../images/carousel_5.png"
            alt="Hero Slide 5"
          />
        </Slider>
      </Grid>
    </Grid>
  );
};
